import { createContext, ReactNode, useState, useCallback } from 'react';
import { AccessToken, Organization } from 'api/models';
// ----------------------------------------------------------------------

export interface AccountContext {
  token: string;
  accountId: string;
}

interface UserContext {
  userId: string;
  name: string;
  email?: string;
  profilePicture?: string;
  userToken?: string;
}

export type UserAccountContextProps = {
  loginType: 'normal' | 'federated' | 'magiclink';

  user: UserContext;

  account: AccountContext;
  isAccountLoading: boolean;

  tokens: { [orgId: string]: { [accountSlug: string]: AccessToken } };

  currentOrganization: Organization;
  isCurrentOrgLoading: boolean;
  organizations: Organization[];
  isOrganizationsLoading: boolean;
  updateUserAccountState: (newState: Partial<UserAccountContextProps>) => void;
  updateTokens: (newTokens: {
    [orgId: string]: { [accountSlug: string]: AccessToken };
  }) => void;
  lastLoginTime: number;
};

const userAccountInitialState: UserAccountContextProps = {
  loginType: 'normal',
  account: {} as AccountContext,
  user: {} as UserContext,
  tokens: {},
  isAccountLoading: false,
  currentOrganization: {} as Organization,
  isCurrentOrgLoading: false,
  organizations: [],
  isOrganizationsLoading: false,
  updateUserAccountState: () => {},
  updateTokens: () => {},
  lastLoginTime: 0,
};
// ----------------------------------------------------------------------

export const UserAccountContext = createContext<UserAccountContextProps>(
  userAccountInitialState,
);

type UserAccountProviderProps = {
  children: ReactNode;
};
export const UserAccountProvider = (
  userAccountProviderProps: UserAccountProviderProps,
) => {
  const [userAccountState, setUserAccountState] =
    useState<UserAccountContextProps>(userAccountInitialState);

  const updateUserAccountState = useCallback(
    (newState: Partial<UserAccountContextProps>) => {
      setUserAccountState((prevState) => ({ ...prevState, ...newState }));
    },
    [],
  );

  const updateTokens = useCallback(
    (newTokens: {
      [orgId: string]: { [accountSlug: string]: AccessToken };
    }) => {
      setUserAccountState((prevState) => ({
        ...prevState,
        tokens: {
          ...prevState.tokens,
          ...newTokens,
        },
      }));
    },
    [],
  );

  return (
    <UserAccountContext.Provider
      value={{ ...userAccountState, updateUserAccountState, updateTokens }}
    >
      {userAccountProviderProps.children}
    </UserAccountContext.Provider>
  );
};
